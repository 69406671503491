import { useEffect, useRef, useState } from 'react';
import { select } from 'd3';
import * as d3 from 'd3';
import Errors from "../../shared/errors";
import { wrap } from "./text-wrap";

function UsersForm(props) {
  const {
    errors,
    prices
  } = props;

  const svgRef: any = useRef();

  type Price = {
    company: string;
    price: number;
  }

  const [previousMaxPrice, setPreviousMaxPrice] = useState<number>(5);


  var margin = { top: 15, right: 10, bottom: 70, left: 50 };
  var width = 1200 - margin.left - margin.right;
  var height = 700 - margin.top - margin.bottom;

  const svg: any = select(svgRef.current!);

  const transisitionDuration = 1000;
  const fontSize = 22;

  svg.attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);



  var xScale = d3.scaleBand()
    .domain(prices.map(d => d.company))
    .range([margin.left, width])
    .padding(0.3);

  svg.selectAll(".xAxis").remove();
  svg
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .attr("stroke-width", "3px")
    .attr("class", "xAxis")
    .attr('dy', `20px`)
    .style("font-size", `${fontSize}px`)
    .style("font-family", "manrope")
    .call(d3.axisBottom(xScale).tickSizeInner(10).tickSizeOuter(0))
    .selectAll(".tick text")
    .attr("transform", "translate(0," + 5 + ")")
    .call(wrap, 120);

  // svg.selectAll(".tick text")
  //   .call(wrap);

  function colorPicker(v) {
    if (v <= 15) {
      return "#947eed";
    } else if (v > 15) {
      return "#5bc0de";
    }
  }


  function render(prices: Price[]) {
    var yScale = d3.scaleLinear()
      .domain([0, previousMaxPrice])
      .range([height, margin.top])
      .nice();

    svg.selectAll(".yAxis").remove();
    var yAxis = svg
      .append('g')
      .attr("class", "yAxis")
      .attr("stroke-width", "3px")
      .style("font-size", `${fontSize}px`)
      .style("font-family", "manrope")
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(yScale));

    var update = svg.selectAll('rect')
      .data(prices);

    update.exit()
      .transition()
      .duration(transisitionDuration)
      .attr('y', height)
      .attr('height', 0)
      .remove();

    const maxPrice: number = (d3.max(prices, (d: Price) => d.price) || 0) + 1;
    if (maxPrice > 1) {
      setPreviousMaxPrice(maxPrice);
      yScale.domain([0, maxPrice])
        .range([height, margin.top])
        .nice();
    }

    yAxis
      .transition()
      .duration(transisitionDuration)
      .call(d3.axisLeft(yScale));

    update
      .transition()
      .duration(transisitionDuration)
      .attr('y', d => yScale(d.price))
      .attr('height', d => height - yScale(d.price));

    update
      .enter()
      .append('rect')
      .attr("fill", function (d) {
        return colorPicker(d.price); // call the color picker to get the fill.
      })
      .attr('y', height)
      .attr('height', 0)
      .attr('x', (d: Price) => xScale(d.company) || 0)
      .attr('width', d => xScale.bandwidth())
      .transition()
      .duration(transisitionDuration)
      .attr('y', d => yScale(d.price))
      .attr('height', d => height - yScale(d.price));

    svg.selectAll(".price-label")
      .data(prices)
      .exit()
      .transition()
      .duration(transisitionDuration)
      .attr('y', height)
      .attr('height', 0)
      .attr("opacity", 0)
      .remove();

    svg.selectAll(".price-label")
      .data(prices)
      .enter()
      .append("text")
      .attr("x", (d: Price) => { return (xScale(d.company) || 0) + ((xScale(d.company) || 0) > 1000 ? 1 : 7) })
      .attr("y", height)
      .attr("opacity", 0)
      .transition()
      .duration(transisitionDuration)
      .attr("y", (d: Price) => yScale(d.price))
      .attr("opacity", 1)
      .attr("dy", "-0.6rem")
      .style("font-family", "manrope")
      .style("font-size", "22px")
      .attr("fill", "white")
      .attr("class", "price-label")
      .text((d: Price) => "€" + d.price.toFixed(2));

    svg.selectAll(".price-label")
      .data(prices)
      .transition()
      .duration(1000)
      .attr('y', d => yScale(d.price));

  }

  const [dataIndex, setDataIndex] = useState<number>(0);

  const refresh = () => {
    render(prices.slice(0, dataIndex));
    if (dataIndex >= prices.length) {
      //setDataIndex(1);
    } else {
      setDataIndex(dataIndex + 1);
    }
  };

  const rerun = () => {
    setPreviousMaxPrice(5);
    setDataIndex(0);
  }

  useEffect(() => {
    if (dataIndex <= prices.length) {
      setTimeout(() => refresh(), 1300);
    }
  }, [dataIndex]);

  useEffect(() => {
    refresh();
  }, []);

  //transform="translate(600 200)"
  return (
    <>
      <h2 className="menu-label">Competitor Prices</h2>
      <div className='is-centered mb-1' style={{ width: width, textAlign: "center", fontSize: "30px" }}>Monthly minimum price</div>

      <svg width={width} height={height} ref={svgRef}>
        <g >
          <g >
          </g>

        </g>
      </svg>
      {errors && <Errors errors={errors} />}
      <br />
      <button className="button mb-1" onClick={rerun}>Re-run</button>
    </>
  );
}

export const convertDateToUTCISO = (date: Date) => {
  var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), 0,
    0, 0));
  return utcDate.toISOString().substring(0, 10);
}

export default UsersForm;